import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import Img from "../components/image-transform"

const Banner = ({content}) => {
  return (
    <div className="section-banner">
      <div className="bg"></div>
      <div className="container">
        <div className="content-wrapper">
          <div className="text-holder">
            {content.title ? <h1 className="title">{content.title}</h1> : '' }
            {content.description ? 
              content.description.map((item, i) => {
                return (
                  <p className="descrption" key={i}>{item.text}</p> 
                )
               }
              )
              : 
              ''
            }
            {content.buttonText ? 
              content.isExternal ?
                <a href={content.url} target="_blank" rel="noopener noreferrer" className={classNames('btn', 
                {"btn-orange" : content.buttonColor === "orange"}
              )}>{content.buttonText}</a> 
              :
                <Link to={content.url} className={classNames('btn', 
                {"btn-orange" : content.buttonColor === "orange"}
              )}>{content.buttonText}</Link> 
            : 
            ''     
          }
          </div>
          <div className="image-holder">
            {content.image &&
            content.svg ?
            <img src={content.image} className="image" alt={content.imageName}/>
            :
            <Img filename={content.image} className="image" alt={content.imageName}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner