import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
// import { library, config } from '@fortawesome/fontawesome-svg-core'
// config.autoAddCss = false
// import { Link } from "gatsby"
import classNames from 'classnames'

const List = ({content}) => {
  return (
    <div className="section-list">
      <div className="container">
        <div className="content" >
          <div className="title-wrapper" style={{ backgroundImage: `url(${content.backgroundImage})`}}>
            <h2 className="title">{content.title}</h2>
          </div>
          {content.listFirstSet 
          ?
          <div className="list">
            <div className="list-wrapper">
              {
                content.listFirstSet.map((item, i) => {
                  return (
                    <p className="item" key={i}><span><FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/></span>{item.title}</p>
                  )
                })
              }
            </div>
            {content.listSecondSet 
            ?
            <div className="list-wrapper">
              {
                content.listSecondSet.map((item, i) => {
                  return (
                    <p className="item" key={i}><span><FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/></span>{item.title}</p>
                  )
                })
              }
            </div>
            :
            ''
          }
          </div>
          :
          ''
          }

          {
            content.description
            ?
            <div className="description-wrapper">
              <p className="description">{content.description}</p>
            </div>
            :
            ''
          }

          {
            content.buttonText
            ?
            <div className="button-wrapper">
              <a target="_blank"  rel="noopener noreferrer"
                href={content.url}
                className={classNames('btn', 
                {"btn-orange" : content.buttonColor === "orange"},
                {"btn-white" : content.buttonColor === "white"},
                {"btn-black" : content.buttonColor === "black"},
                {"btn-blue" : content.buttonColor === "blue"}, 
              )}>{content.buttonText}</a>
            </div>
            :
            ''
          }
        </div>
      </div>
    </div>
  )
}

export default List