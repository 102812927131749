import React from "react"

const YouDontGet = ({content}) => {
  return (
    <div className="you-dont-get limited">
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <div className="grid-listing">
          {content.gridListing.map((gridItem, i) => {
            return (
              <div className="grid-item" key={i}>
                <h3 className="grid-item-title">{gridItem.title}</h3>
                <p className="grid-item-description">{gridItem.description}</p>
              </div>
            )
          })}
        </div>

        <div className="listing">
          {content.listing.map((listItem, j) => {
            return (
            <div className="list-item" key={j}>
              <span className="list-item-title">{listItem.title}</span><span> - </span><span className="list-item-description">{listItem.description}</span>
            </div>
            )
          })}
        </div>

        <div className="limit"></div>
      </div>
    </div>
  )
}

export default YouDontGet