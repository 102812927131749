import React from "react"
import { Link } from "gatsby"
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  // TabDivider
} from '../components/tabs';
import classNames from 'classnames'

const ProTabs = ({content}) => {
  return (
    <>
      <div
       className={classNames('section-pro-tabs', {"black-background" : content.backgroundColor === "black"})}>
        <div className="container">
          <div className="content">
            <img className="billsby-logo" src={require('../images/billsby-logo.svg')} alt="Logo" />
            <img className="pro-logo" src={require('../images/pro.png')}  alt="Pro Icon" />
            <h2>{content.title}</h2>
            
            {
              content.tabs ? 
                <Tabs initialValue={1}>
                  <TabList>
                    {content.tabs.map((item, i) => {
                      return (
                        <Tab name={i} key={i}>
                          <div>{item.tabTitle}</div>
                        </Tab>
                      )
                    })}
                  </TabList>
                  {content.tabs.map((item, i) => {
                    return (
                      <TabPanel name={i} key={i}>
                        <div className="price-wrapper">
                            {item.price.map((price, j) => {
                              return (
                                <div className="price-item" key={j}>
                                  <h3 className="price-title">{price.title}</h3>
                                  <p className="price-detail">{price.detail}</p>
                                </div>
                              )
                            })}
                        </div>

                        {/* <div className="cards-wrapper">
                          {item.cards.map((card, k) => {
                            return (
                              <Link className="card-item" key={k} to={card.url}>
                                {card.title}
                              </Link>
                            )
                          })}
                        </div> */}
                      </TabPanel>
                    )
                  })}
                </Tabs> : <div className="coming-soon">Coming soon</div>
            }


            <div className="cards-wrapper">
              {content.cardLinks.map((card, k) => {
                return (
                  <Link className="card-item" key={k} to={card.url}>
                    {card.title}
                  </Link>
                )
              })}
              {/* {content.cards} */}
            </div>  
          </div>

          {/* <img className="tabs-image" src={require('../images/proprices.png')} srcSet="proprices@2x.png 2x" alt="ProPrices" /> */}
        </div>
      </div>
    </>
  )
}

export default ProTabs