import React from 'react'
// import { Helmet } from 'react-helmet'
import SEO from "../components/seo"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import List from "../components/list"
import ProTabs from '../components/pro-tabs'
import FreeTrial from '../components/free-trial'
import YouDontGet from '../components/you-dont-get'
import FindOutMoreCTA from '../components/find-out-more-cta'
import Testimonial from '../components/testimonial'
// import { testimonialContent } from "../lib/testimonial-content"

import BadgeContent from "../components/badge-component"

const badgeData = {
  section: "home",
  link: true,
  FallBadges: [
    {
      img: require("../images/fastest-implementation-fall.svg"),
      altText: 'fastest-implementation-fall',
    },
    {
      img: require("../images/best-est-roi-fall.svg"),
      altText: 'best-est-roi-fall',
    },
  ],
}

const bannerContent = {
  title: <span>$0 per month. <br/> 0.4% transaction fee. <br/> Start with a $5,000 free trial.</span>,
  description: [
    {
      text: 'Our Billsby Complete plan includes all the features you need to grow your subscription business. With no minimum charges or obligatory fees, we only make money when you do.'
    },
    {
      text: 'Every Billsby customer gets unlimited products, plans, cycles, subscriptions, customers, teammates, payment gateways, countries, currencies and tax profiles'
    },
  ],
  isExternal: true,
  url: 'https://app.billsby.com/registration',
  buttonText: 'Get started with your free trial account',
  buttonColor: 'orange',
  svg: true,
  isBanner: true,
  image: require('../images/pricing-banner.svg')
}

const listContent = {
  title: 'Create products, plans and cycles',
  backgroundImage: require('../images/list-title-bg1@2x.png'),
  listFirstSet: [
    {
      title: 'Free trials',
    },
    {
      title: 'Setup fees',
    },
    {
      title: 'Contact terms',
    },
    {
      title: 'Pro-rating',
    },
    {
      title: 'Feature tags',
    },
  ],
  listSecondSet: [
    {
      title: 'Add-ons',
    },
    {
      title: 'Allowances and counters',
    },
    {
      title: 'Flat-fee, per unit, volume, ranged and tiered plans',
    },
    {
      title: 'Coupon codes and discounts',
    }
  ],
}

const listContent2 = {
  title: 'Branding and customization',
  backgroundImage: require('../images/list-title-bg2@2x.png'),
  listFirstSet: [
    {
      title: 'Branded invoices',
    },
    {
      title: 'Branded emails',
    },
    {
      title: 'Branded checkout and account management',
    },
    {
      title: 'Custom taxonomy',
    },
    {
      title: 'Include adverts in emails and invoices',
    },
  ],
  listSecondSet: [
    {
      title: 'Integrate support channels',
    },
    {
      title: 'Custom email copy',
    },
    {
      title: 'Custom email addresses',
    },
  ],
}

const listContent3 = {
  title: 'Advanced billing tools',
  backgroundImage: require('../images/list-title-bg3@2x.png'),
  listFirstSet: [
    {
      title: 'Billsby Checkout',
    },
    {
      title: 'Card tokenizer',
    },
    {
      title: 'Custom fields',
    },
    {
      title: 'Customer data pass through',
    },
    {
      title: 'Unlimited payment gateways',
    },
  ],
  listSecondSet: [
    {
      title: 'Billsby Vault',
    },
    {
      title: 'Global tax compliance',
    },
    {
      title: 'Advanced invoices and credit notes',
    },
  ],
}

const listContent4 = {
  title: 'Self-service and retention',
  backgroundImage: require('../images/list-title-bg4@2x.png'),
  listFirstSet: [
    {
      title: 'Self-services account management',
    },
    {
      title: 'Advanced dunning journeys',
    },
    {
      title: 'Retention tools',
    },
  ]
}

const listContent5 = {
  title: 'Metrics and reporting',
  backgroundImage: require('../images/list-title-bg5@2x.png'),
  listFirstSet: [
    {
      title: 'Billsby Dashboard',
    },
    {
      title: 'Billsby Briefing',
    },
    {
      title: 'Activity summary',
    },
    {
      title: 'Checkout performance',
    },
    {
      title: 'Customer and subscription summaries',
    },
  ],
  listSecondSet: [
    {
      title: 'Exit reasons',
    },
    {
      title: 'Product, plan and cycle performance',
    },
    {
      title: 'Tax summary',
    },
    {
      title: 'Unpaid invoices',
    },
  ],
}

const listContent6 = {
  title: 'Powerful integrations',
  backgroundImage: require('../images/list-title-bg6@2x.png'),
  listFirstSet: [
    {
      title: 'Accounting',
    },
    {
      title: 'API and web hooks',
    },
    {
      title: 'Customer services',
    },
    {
      title: 'Plan actions',
    },
    {
      title: 'Zapier',
    },
  ]
}

const youDontGetContent = {
  title: <>Here's what you <span>don't</span> get with Billsby</>,
  gridListing: [
    {
      title: "Time limited free trials",
      description: "We believe in the power of our platform, and we want you to have as much time as you'd like to try it for yourself – so you can keep your sandbox account forever, and we'll even let you make $5,000 on us before you pay a cent in transaction fees."
    },
    {
      title: "Expensive monthly minimum fees",
      description: "We only want to make money when you make money – so there's no monthly minimum fee to use Billsby, and our transaction fee is really competitive at just 0.4%. We do charge fees for some add-ons, but they're completely optional – just pay for what your business needs to succeed."
    },
    {
      title: "Transaction fees that go up as your business grows",
      description: "Our transaction fee is fixed at 0.4% – low enough to support the growth of your business, and high enough that we don't need to lock features behind more expensive premium plans."
    },
    {
      title: "Pushy sales calls",
      description: "We believe in our product, so you can sign up for a free trial without speaking to a member of the team, and upgrade to a paid plan whenever you're ready. We might call you to ask how things are going, but we'll never make a call a condition of getting started or using the platform."
    },
  ],
  listing: [
    {
      title: "$5,000 free trial",
      description: "For the first $5,000 of revenue collected through Billsby, no transaction fee will apply – excl. additional fees, Billsby Pro and enterprise agreements"
    },
    {
      title: "Additional fees",
      description: "Address validation ($0.05 per address validated) and tax calculation ($0.02 per calculation completed)"
    },
  ]
}

const tabsContent = {
  title: "Get ready to take your subscription business to the next level with premium add-ons available in your Billsby account",
  backgroundColor: "black",
  cardLinks: [
    {
      url: "/pro/advanced-gateways",
      title: "Advanced gateway selection"
    },
    {
      url: "/pro/advanced-value-score/",
      title: "Advanced Value Score"
    },
    {
      url: "/pro/affiliate-scheme/",
      title: "Affiliate schemes"
    },
    {
      url: "/pro/cardless-trials/",
      title: "Cardless trial"
    },
    {
      url: "/pro/debt-collection/",
      title: "Debt collection"
    },
    {
      url: "/pro/membership-cards/",
      title: "Membership cards"
    },
    {
      url: "/pro/priority-support/",
      title: "Priority support"
    },
    {
      url: "/pro/quotes-bespoke-plans/",
      title: "Quotes and bespoke plans"
    },
    {
      url: "/pro/referral-program/",
      title: "Referral programs"
    },
    {
      url: "/pro/revenue-recognition/",
      title: "Revenue recognition"
    },
    {
      url: "/pro/whitelabel/",
      title: "White label"
    }
  ]
}

const findOutMoreCTAContent = {
  title: "Don't want to do it yourself?",
  description: "Our Billsby Pro Development Services team can develop custom integrations and help you get started with Billsby. The service costs just $25 per hour - and it's really easy to get started",
  backgroundColor: '#fff',
  buttons: [
    {
      url: "/pro/development",
      buttonText: "Find out more",
      buttonColor: "blue"
    }
  ]
}

const trialContent = {
  title: "Start your free trial of Billsby today",
  description: "When you try Billsby for yourself, you'll make your first $5,000 of subscription revenue on us - and you don't even need a credit card to get started. The best way to get to know Billsby is to try it for yourself.",
  svg: true,
  image: require("../images/free-trial.svg"),
  imageName: 'free-trial image',
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Get started with your free trial account',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ]
}

const Pricing = () => {
  return (
    <Layout>
      <SEO 
        title="$5k free trial then 0.4% of revenue share | Billsby" 
        description="The most competitively priced subscription billing software around can process recurring payments for your business. Start your $5,000 free trial today." 
        url="https://www.billsby.com/pricing"
      />
      <>
      <div className="pricing-banner-with-badge">
        <Banner content={bannerContent}/>
        <BadgeContent content={badgeData} />
      </div>
      <List content={listContent} />
      <List content={listContent2} />
      <List content={listContent3} />
      <List content={listContent4} />
      <List content={listContent5} />
      <List content={listContent6} />
      <YouDontGet content={youDontGetContent}/>
      <ProTabs content={tabsContent} />
      <FindOutMoreCTA content={findOutMoreCTAContent} />
      <Testimonial />
      <FreeTrial content={trialContent}/>
      </>
    </Layout>
  )
}

export default Pricing
