import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const FindOutMoreCTA = ({content}) => {
  return (
    <div className="find-out-more-cta" style={{ backgroundColor: content.backgroundColor }}>
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <p className="description">{content.description}</p>
        <div className="button-wrapper">
          {
            content.buttons.map((btn, i) => {
              return (
              content.buttons
              ?
                btn.scheduleButton 
                ?
                <ScheduleADemoButton key={i} buttonColor={btn.buttonColor}/>
                :
                btn.isExternal === true
                ?
                <a 
                  href={btn.url}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames('btn', 
                    {"btn-orange" : btn.buttonColor === "orange"},
                    {"btn-black" : btn.buttonColor === "black"},
                    {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}</a>
                :
                <Link 
                  to={btn.url}
                  key={i}
                  className={classNames('btn', 
                    {"btn-orange" : btn.buttonColor === "orange"},
                    {"btn-black" : btn.buttonColor === "black"},
                    {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}
                </Link>
              :
              ''
              // <Link 
              // to={content.url}
              // key={i}
              // className={classNames('btn', 
              //   {"btn-orange" : btn.buttonColor === "orange"},
              //   {"btn-black" : btn.buttonColor === "black"},
              //   {"btn-blue" : btn.buttonColor === "blue"})}>{btn.buttonText}</Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default FindOutMoreCTA